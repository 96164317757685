<script lang="ts" setup>
import {usePageStore} from "~/stores/page";
import {useSettingsStore} from "~/stores/settings";
import type {WpMedia} from "~/integration/wordpress/types";
import {useWp} from "~/composables/Redmonkey";

const pageStore = usePageStore();
const settingsStore = useSettingsStore();

const demoFormPage = pageStore?.routeData?.demoForm;
const demoFormSettings = settingsStore?.redmonkeySettings?.demoForm;

const DemoFrmForm = defineAsyncComponent(() => import("~/components/Redmonkey/FrmForm/FrmFormWrapper.vue"))
const TitleSecondary = defineAsyncComponent(() => import("~/components/Redmonkey/Base/TitleSecondary.vue"))

const {
  getMediaAltFromMedia,
  getMediaUrlFromMedia,
  getMediaWidthFromMedia,
  getMediaHeightFromMedia,
  extractShortcodeParam
} = useWp();

const isEnabled = () => {
  return !(demoFormPage?.disableDemoForm || demoFormSettings?.disableDemoForm);
}

const getImage = () => {
  if (demoFormPage?.demoFormImage?.node as WpMedia) {
    return {
      url: getMediaUrlFromMedia(demoFormPage.demoFormImage.node),
      alt: getMediaAltFromMedia(demoFormPage.demoFormImage.node) || getTitle(),
      width: getMediaWidthFromMedia(demoFormPage.demoFormImage.node) || 'auto',
      height: getMediaHeightFromMedia(demoFormPage.demoFormImage.node) || 'auto'
    }
  }

  if (demoFormSettings?.demoFormImage?.node as WpMedia) {
    return {
      url: getMediaUrlFromMedia(demoFormSettings.demoFormImage.node),
      alt: getMediaAltFromMedia(demoFormSettings.demoFormImage.node) || getTitle(),
      width: getMediaWidthFromMedia(demoFormSettings.demoFormImage.node) || 'auto',
      height: getMediaHeightFromMedia(demoFormSettings.demoFormImage.node) || 'auto'
    }
  }

  return {
    url: '',
    alt: '',
    width: 'auto',
    height: 'auto'
  }
}

const getTitle = () => {
  return demoFormPage?.demoFormTitle || demoFormSettings?.demoFormTitle;
}

const getFormId = () => {
  if (demoFormPage?.demoFormShortcode) return extractShortcodeParam(demoFormPage.demoFormShortcode, 'id');
  if (demoFormSettings?.demoFormShortcode) return extractShortcodeParam(demoFormSettings.demoFormShortcode, 'id');
}
</script>
<template>
  <div class="demo-form">
    <div class="container">
      <div class="demo-form-content" v-if="isEnabled() && getImage().url && getFormId()">
        <div>
          <TitleSecondary v-if="getTitle()" :title="getTitle()"/>
        </div>
        <div class="demo-form-sides flex wrap justify">
          <div class="demo-form-image">
            <picture>
              <img :src="getImage().url" :alt="getImage().alt" :width="getImage().width" :height="getImage().height"/>
            </picture>
          </div>
          <div class="demo-form-form">
            <div>
              <DemoFrmForm :form-id="getFormId()" :key="`FORM_${getFormId()}`"></DemoFrmForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "assets/css/source/components/demo_form";
</style>